<template>
  <div class="ak-text container-layout-custom bg-lmsBackground primaryText-text" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-end">
        <div class="text-h4">Required Training</div>
      </div>

      <div class="row full-width">
        <q-tabs v-model="moduleTab" align="justify" class="col-12">
          <q-tab name="toDo" label="To Do" />
          <q-tab name="complete" label="Completed" />
        </q-tabs>
        <div class="col-12">
          <q-separator />
        </div>
      </div>
      <div class="row full-width">
        <q-tab-panels v-model="moduleTab" class="row full-width bg-lmsBackground">
          <q-tab-panel name="toDo">
            <q-list separator class="full-width" v-if="unfinishedModules.length > 0">
              <module-list-item v-for="unfinishedModule in unfinishedModules" :module="unfinishedModule['module']" :key="unfinishedModule['module'].uid" :bookmarkable="false">
                <template v-slot:topRightAction>
                  <q-chip dense square v-if="unfinishedModule.assignment.started_at !== null" color="info" class="q-pa-sm">
                    Started on {{formatDate(unfinishedModule.assignment.started_at)}}
                  </q-chip>
                </template>
                <template v-slot:topRightAction>
                  <q-chip dense square v-if="unfinishedModule.assignment.due_date !== null" color="warning" class="q-pa-sm">
                    Due on {{formatDate(unfinishedModule.assignment.due_date)}}
                  </q-chip>
                </template>
              </module-list-item>
            </q-list>
            <div class="row full-width justify-center" v-else>
                <div class="text-h5 text-italic q-pa-md">You do not have any required training</div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="complete">
            <q-list separator class="full-width" v-if="completedModules.length > 0">
              <module-list-item v-for="completedModule in completedModules" :module="completedModule['module']" :key="completedModule['module'].uid" :bookmarkable="false">
                <template v-slot:topRightAction>
                  <q-chip dense square color="positive" class="q-pa-sm">
                    Completed on {{formatDate(completedModule.assignment.completed_at)}}
                  </q-chip>
                </template>
              </module-list-item>
            </q-list>
            <div class="row full-width justify-center" v-else>
                <div class="text-h5 text-italic q-pa-md">You have not completed any required training</div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar'
import ModuleListItem from 'components/ModuleListItem'

export default {
  name: 'MyTrainingPage',
  components: { ModuleListItem },
  data () {
    return {
      completedModules: [],
      unfinishedModules: [],
      moduleTab: 'toDo'
    }
  },
  methods: {
    formatDate (pDate) {
      return date.formatDate(pDate, 'MM/DD/YYYY')
    }
  },
  created () {
    this.$moduleSearch.clearCache()
    this.$moduleSearch.search([
      {
        indexName: this.$consts.MODULES_INDEX,
        query: '',
        params: {
          hitsPerPage: 100,
          facetFilters: [this.$store.getters['assignments/data'].map(assignment => `uid:${assignment.assignable_uid}`)]
        }
      }
    ], (err, content) => {
      if (err) throw err
      this.$store.getters['assignments/unfinished'].forEach(function (assignment) {
        this.unfinishedModules.push({
          assignment: assignment,
          module: content.results['0'].hits.filter(hit => hit.uid === assignment.assignable_uid)['0']
        })
      }, this)

      this.$store.getters['assignments/completed'].forEach(function (assignment) {
        this.completedModules.push({
          assignment: assignment,
          module: content.results['0'].hits.filter(hit => hit.uid === assignment.assignable_uid)['0']
        })
      }, this)
    })
  }
}
</script>

<style lang="stylus" scoped>
.dueChip
  background-color #F29B9B
  color #610404
.completedChip
  background-color #A3D9A5
  color #05400A
.startedChip
  background-color #CFCFCF
  color #222222
</style>
